import React from 'react';
import { graphql } from 'gatsby';
import { convertCategoryName } from '../components/CategoryLink';
import Heading from '../components/Heading';
import Layout from '../components/Layout';
import Ogp, { SiteProps } from '../components/Ogp';
import PostList, { PostListProps } from '../components/PostList';
import ArticleIcon from '../icons/Article/Article';

type Props = {
  data: {
    site: SiteProps;
    allMarkdownRemark: {
      posts: PostListProps;
    };
  };
  pageContext: {
    category: string;
  };
};

const Categories = (props: Props) => {
  const { data, pageContext } = props;
  const { posts } = data.allMarkdownRemark;
  const title = `カテゴリ "${convertCategoryName(pageContext.category)}" の記事一覧`;
  return (
    <Layout>
      <Ogp metadata={data.site} title={title} />
      <Heading icon={<ArticleIcon alt={title} />}>{title}</Heading>
      <PostList posts={posts} />
    </Layout>
  );
};

export const query = graphql`
  query CategoryPageQuery($category: String!) {
    site {
      ...OgpFragment
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { category: { eq: $category } } }
    ) {
      posts: edges {
        ...PostListFragment
      }
    }
  }
`;

export default Categories;
